/* App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

/* Set full height and remove margins */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; /* Prevent scrollbars */
}

/* Background image with Ken Burns effect */
.background-container {
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  background-image: url('./assets/wallpaper.webp'); /* Path to your image */
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents repeating the image */
  background-attachment: fixed; /* Fixes the background relative to the viewport */
  animation: kenburns 40s ease-in-out infinite; /* Slower Ken Burns effect */
  position: relative;
  overflow: hidden; /* Prevent scrollbars */
}

/* Keyframes for Ken Burns Effect */
@keyframes kenburns {
  0% {
    transform: scale(1) translate(0, 0); /* Initial state */
  }
  50% {
    transform: scale(1.05) translate(-5px, -5px); /* Smaller zoom and slight pan */
  }
  100% {
    transform: scale(1) translate(0, 0); /* Return to original state */
  }
}

.title {
  position: absolute;
  top: 5%;
  left: 5%;
  font-family: 'Great Vibes', cursive; /* Use the imported handwriting font */
  font-size: 2rem; /* Adjust the size as needed */
  color: #000; /* Adjust the color as needed */
}

.signature {
  position: absolute;
  bottom: 10%;
  right: 5%;
  font-family: 'Great Vibes', cursive; /* Use the imported handwriting font */
  font-size: 2rem; /* Adjust the size as needed */
  color: #000; /* Adjust the color as needed */
  transform: rotate(-30deg); /* Tilt the text to the left by 15 degrees */
}
